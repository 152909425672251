






































import { Vue, Component } from 'vue-property-decorator';
import { TemplateItem, getTemplates, templateStatus } from '@/api/fileTemplate';
import { CuType } from './interface';
import Cu from './cu.vue';
import { Pagination } from 'ant-design-vue';

const initRecord: TemplateItem = {
  index: 1,
  templateName: '',
  templateCode: '',
  templateDes: '',
  templateArea: '',
  status: '',
  sequenceNbr: '',
};

@Component({
  components: { Cu },
})
export default class FileSetting extends Vue {
  columns = [
    {
      title: '编号',
      dataIndex: 'index',
    },
    {
      title: '名称',
      dataIndex: 'templateName',
    },
    {
      title: '模板编码',
      dataIndex: 'templateCode',
    },
    {
      title: '描述',
      dataIndex: 'templateDes',
    },
    {
      title: '适用地区',
      dataIndex: 'templateArea',
    },
    {
      title: '状态',
      dataIndex: 'statusName',
    },
    {
      title: '操作',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
    },
  ];
  data: TemplateItem[] = [];
  visible = false;
  type: CuType = 'create';
  record = initRecord;
  loading = false;
  pagination = {
    current: 1,
    pageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40', '50'],
    total: 0,
    showQuickJumper: true,
    showTotal: (total: number): string => `共 ${total} 条`,
  };

  created(): void {
    this.refresh();
  }

  cu(type: CuType, record: TemplateItem | null): void {
    this.visible = true;
    this.type = type;
    this.record = record || initRecord;
  }

  del(record: TemplateItem): void {
    console.log(record.sequenceNbr);
  }

  handleChange(pagination: Pagination): void {
    const { current, pageSize } = pagination;
    Object.assign(this.pagination, { current, pageSize });
    this.fetchData();
  }

  refresh(): void {
    this.pagination.current = 1;
    this.fetchData();
  }

  fetchData(): void {
    this.loading = true;
    getTemplates({
      page: this.pagination.current,
      limit: this.pagination.pageSize,
    })
      .then((res) => {
        const { total, list } = res.result;
        this.pagination.total = total;
        this.data = list.map((item, i) => ({
          ...item,
          index: i + 1,
          statusName: item.status === 'A' ? '启用' : '禁用',
        }));
      })
      .finally(() => {
        this.loading = false;
      });
  }

  toSheet(record: TemplateItem): void {
    this.$router.push({
      path: '/setting/excel/sheets',
      query: { id: record.sequenceNbr },
    });
  }

  changeStatus(record: TemplateItem): void {
    templateStatus({
      sequenceNbr: record.sequenceNbr,
      status: record.status === 'A' ? 'I' : 'A',
    }).then(() => {
      this.$message.success('操作成功');
      this.refresh();
    });
  }
}
